import { reactive, ref } from "vue";
import { list } from "@/api/maintenance/pdaApi";
import useMessage from "@/hooks/useMessage";
const usePda = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const pdaStates = ref([
    { value: 1, label: "启用" },
    { value: 0, label: "停用" },
  ]);

  const pdaVo = reactive({
    id: "",
    name: "",
    code: "",
      sn:"",
    state: null,
    createTime:"",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "pda编号",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "设备名称",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "状态",
      align: "center",
      dataIndex: "state",
      slots: { customRender: "state" },
    },
    {
      title: "添加时间",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
      let p = {
          code: params.code,
          state: params.state,
          createTimeArr: params.createTimeArr ? params.createTimeArr.toString() : [],
      };
    try {
      let res = await list(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    code: "",
    state: null,
    createTimeArr:[],
  });
  return {
    loading,
    params,
    dataList,
    total,
    pdaStates,
    pdaVo,
    columns,
    loadData
  };
};
export default usePda;
